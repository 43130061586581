import React, {Component} from 'react';
import data from "../../utils/data/rankings";
import WidgetRankings from "./widget-rankings";

class WidgetClocksContainer extends Component {
  render() {
    return (
      <WidgetRankings dataRankings={data} />
    );
  }
}

export default WidgetClocksContainer;