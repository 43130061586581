import ecommerce_spent from "./ecommerce_spent";
import twitter_tweets from "./twitter_tweets";
import facebook_likes from "./facebook_likes";
import facebook_comments from "./facebook_comments";
import facebook_clicks_ads from "./facebook_clicks_ads";
import spotify_minutes from "./spotify_minutes";
import tinder_visits from "./tinder_visits";
import instagram_likes from "./instagram_likes";
import instagram_stories from "./instagram_stories";
import youtube_hours from "./youtube_hours";
import netflix_hours from "./netflix_hours";
import emails_sent from "./emails_sent";
import sms_sent from "./sms_sent";
import whatsapp_message from "./whatsapp_message";
import whatsapp_video from "./whatsapp_video";
import google_searches from "./google_searches";

const data = [
  ecommerce_spent,
  twitter_tweets,
  facebook_likes,
  facebook_clicks_ads,
  facebook_comments,
  spotify_minutes,
  tinder_visits,
  instagram_likes,
  instagram_stories,
  youtube_hours,
  netflix_hours,
  emails_sent,
  sms_sent,
  whatsapp_message,
  whatsapp_video,
  google_searches,
];

export default data;