export default {
  // Info general para iconos
  icon: 'instagram',
  slug: 'instagram-stories',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Creamos **clock** historias.',
    clock: {
      valuePerSecond: 115.74,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, Instagram tiene 1.000 millones de usuarios que se dan 1.533.000.000.000 likes al año.',
    highlight: '1.000 millones',
    sources: [
      {text: 'Omnicore, enero 2020', url: 'https://www.omnicoreagency.com/instagram-statistics/'},
    ],
  }, {
    text: 'En Argentina, hay 20.000.000 de usuarios.',
    highlight: '20.000.000',
    sources: [
      {text: 'Statista, enero 2020', url: 'https://es.statista.com/estadisticas/875244/porcentaje-de-usuarios-de-instagram-en-paises-seleccionados/'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs creamos 3.650.000.000 historias al año en Instagram.',
      highlight: '3.650.000.000',
    },
    all: {
      perSecond: '116 historias',
      perDay: '10.000.000 historias',
      perYear: '3.650.000.000 historias',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: '2 historias',
      perMonth: '7 historias',
      perYear: '88 historias',
      note: '*Si todos los argentinos conectados usaran Instagram.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
