export default {
  // Info general para iconos
  icon: 'facebook',
  slug: 'facebook-likes',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Dimos **clock** likes.',
    clock: {
      valuePerSecond: 168,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, Facebook tiene 2.410 millones de usuarios mensualmente activos.',
    highlight: '2.410 millones',
    sources: [
      {text: 'Hootsuite, noviembre 2019', url: 'https://blog.hootsuite.com/facebook-statistics/'},
    ],
  }, {
    text: 'Argentina representa el 1% de ese total.',
    highlight: '1%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/facebook.com'},
    ]
  }, {
    text: 'Cada argentinx da un promedio de 15 likes por mes.',
    highlight: '15 likes',
    sources: [
      {text: 'DataReportal, Digital 2020 Argentina-chart 46, enero 2020', url: 'https://datareportal.com/reports/digital-2020-argentina'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs damos 5.298.048.000 de likes al año en Facebook.',
      highlight: '5.298.048.000',
    },
    all: {
      perSecond: '168 likes',
      perDay: '14.515.200 likes',
      perYear: '5.298.048.000 likes',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: '3 likes',
      perMonth: '11 likes',
      perYear: '127 likes',
      note: '*Si todos los argentinos conectados usaran Facebook.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};