

export const google_trends = {
  slug: 'google_trends',
  icon: 'google',
  text: 'Tendencias de búsquedas',
  subtext: 'últimos 60 minutos',

  // El ranking se carga automaticamente desde Google Trends
  ranking: [],

  sources: [
    {text: 'Google Trends', url: 'https://trends.google.com.ar/trends/'}
  ],
};

export const twitter_trends = {
  slug: 'twitter_trends',
  icon: 'twitter',
  text: 'Lo más hablado',
  subtext: 'últimos 60 minutos',

  // El ranking se carga automaticamente desde Twitter
  ranking: [],

  sources: [
    {text: 'Twitter', url: 'https://twitter.com'}
  ],
};

export const facebook_users = {
  slug: 'facebook_users',
  icon: 'facebook',
  text: 'Usuarios con más seguidores',
  subtext: 'julio 2020',

  ranking: [
    {text: 'Leo Messi', count: '90.098.678'}, // 1
    {text: 'Sergio Aguero', count: '10.852.279'}, // 2
    {text: 'Diego Maradona', count: '8.895.452'}, // 3
  ],

  sources: [
    {text: 'socialbakers, julio 2020', url: 'https://www.socialbakers.com/statistics/facebook/pages/total/argentina/celebrities'}
  ],
};

export const facebook_companies = {
  slug: 'facebook_companies',
  icon: 'facebook',
  text: 'Empresas con más seguidores',
  subtext: 'julio 2020',

  ranking: [
    {text: 'Quimes Cerveza', count: '4.311.375'}, // 1
    {text: 'Personal Argentina', count: '3.179.713'}, // 2
    {text: 'Movistar Argentina', count: '3.031.169'}, // 3
  ],

  sources: [
    {text: 'socialbakers, julio 2020', url: 'https://www.socialbakers.com/statistics/facebook/pages/total/argentina/brands'}
  ],
};

export const twitter_users = {
  slug: 'twitter_users',
  icon: 'twitter',
  text: 'Usuarios con más seguidores',
  subtext: 'julio 2020',

  ranking: [
    {text: 'Sergio Kun Agüero', count: '13.601.963'}, // 1
    {text: 'Marcelo Tinelli', count: '10.355.807'}, // 2
    {text: 'Javier Mascherano', count: '8.145.272'}, // 3
  ],

  sources: [
    {text: 'socialbakers, julio 2020', url: 'https://www.socialbakers.com/statistics/twitter/profiles/argentina'}
  ],
};

export const twitter_companies = {
  slug: 'twitter_companies',
  icon: 'twitter',
  text: 'Empresas con más seguidores',
  subtext: 'julio 2020',

  ranking: [
    {text: 'Estado SUBTE BA', count: '958.585'}, // 1
    {text: 'Ricky Sarkany', count: '824.644'}, // 2
    {text: 'Club La Nación', count: '677.570'}, // 3
  ],

  sources: [
    {text: 'socialbakers, julio 2020', url: 'https://www.socialbakers.com/statistics/twitter/profiles/argentina/brands'}
  ],
};

export const youtube_channels = {
  slug: 'youtube_channels',
  icon: 'youtube',
  text: 'Canales con más seguidores',
  subtext: 'julio 2020',

  ranking: [
    {text: 'El Reino Infantil', count: '28.800.000'}, // 1
    {text: 'DrossRotzank', count: '18.300.000'}, // 2
    {text: 'eltrece', count: '7.080.000'}, // 3
  ],

  sources: [
    {text: 'socialbakers, julio 2020', url: 'https://www.socialbakers.com/statistics/youtube/channels/argentina'}
  ],
};

export const android_apps = {
  slug: 'android_apps',
  icon: 'android',
  text: 'Apps más descargadas',
  subtext: 'julio 2020',

  ranking: [
    {text: 'CUIDAR COVID-19', count: ''}, // 1
    {text: 'Mercado Libre', count: ''}, // 2
    {text: 'Mercado Pago', count: ''}, // 3
    {text: 'Google Meet', count: ''}, // 4
    {text: 'Amazon Prime', count: ''}, // 5
  ],

  sources: [
    {text: 'apptweak, julio 2020', url: 'https://www.apptweak.com/free-aso-tools/top-charts'}
  ],
};

export const ios_apps = {
  slug: 'ios_apps',
  icon: 'ios',
  text: 'Apps más descargadas',
  subtext: 'julio 2020',

  ranking: [
    {text: 'Omega Legends', count: ''}, // 1
    {text: 'CUIDAR COVID-19', count: ''}, // 2
    {text: 'Champions Legion', count: ''}, // 3
    {text: 'Mercado Libre', count: ''}, // 4
    {text: 'Tik Tok', count: ''}, // 5
  ],

  sources: [
    {text: 'apptweak, julio 2020', url: 'https://www.apptweak.com/free-aso-tools/top-charts'}
  ],
};


const data = [
  google_trends,
  twitter_trends,
  facebook_users,
  facebook_companies,
  twitter_users,
  twitter_companies,
  youtube_channels,
  android_apps,
  ios_apps,
];

export default data;