export default {
  // Info general para iconos
  icon: 'spotify',
  slug: 'spotify-minutos',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Escuchamos **clock** horas de música.',
    clock: {
      valuePerSecond: 1184.16,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, Spotify tiene 232 millones de usuarios activos.',
    highlight: '232 millones',
    sources: [
      {text: 'Appinventive, septiembre 2019', url: 'https://appinventiv.com/blog/spotify-statistics-facts/'},
    ],
  }, {
    text: 'Sus usuarios lo usan 148 minutos por día.',
    highlight: '148',
    sources: [
      {text: 'Appinventive, septiembre 2019', url: 'https://appinventiv.com/blog/spotify-statistics-facts/'},
    ]
  }, {
    text: 'Argentina representa el 1,4% del total.',
    highlight: '1,4%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/spotify.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs escuchamos 37.343.661.000 horas de música al año en Spotify.',
      highlight: '37.343.661.000',
    },
    all: {
      perSecond: '1.184 horas',
      perDay: '102.311.400 horas',
      perYear: '37.343.661.000 horas',
    },
    one: {
      perSecond: null,
      perDay: '2:30 horas',
      perWeek: null,
      perMonth: '74:48 horas',
      perYear: '898 horas',
      note: '*Si todos los argentinos conectados usaran Spotify.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
