import React, {Component} from 'react';
import dataAbout from "../../utils/data/about";
import PageAbout from "./page-about";

class PageAboutContainer extends Component {
  render() {
    return (
      <PageAbout data={dataAbout} />
    );
  }
}

export default PageAboutContainer;