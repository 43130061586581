export default {
  // Info general para iconos
  icon: 'tinder',
  slug: 'tinder-vistas',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Accedimos **clock** veces para conocer gente.',
    clock: {
      valuePerSecond: 0.39,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se realizan 92,4 millones de visitas por mes.',
    highlight: '92,4 millones',
    sources: [
      {text: 'SimilarWeb, mes año julio 2020', url: 'https://www.similarweb.com/website/tinder.com/'},
    ],
  }, {
    text: 'Argentina representa el 1,1% del tráfico.',
    highlight: '1,1%',
    sources: [
      {text: 'Alexa, junio 2020', url: 'https://www.alexa.com/siteinfo/tinder.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs realizamos 12.196.800 de visitas al año a Tinder.',
      highlight: '12.196.800',
    },
    all: {
      perSecond: '33.416 visitas',
      perDay: null,
      perYear: '12.196.800 visitas',
    },
    one: {
      perSecond: null,
      perDay:null,
      perWeek: null,
      perMonth: null,
      perYear: '0,3 visitas',
      note: '*Si todos los argentinos conectados usaran Tinder.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};