export default {
  // Info general para iconos
  icon: 'whatsapp',
  slug: 'whatsapp-videollamadas',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Hicimos **clock** minutos de llamadas y videollamada.',
    clock: {
      valuePerSecond: 486.11,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, Whatsapp tiene 2.000 millones de usuarios.',
    highlight: '2.000 millones',
    sources: [
      {text: 'Whatsapp Blog, febrero 2020', url: 'https://blog.whatsapp.com/?lang=es'},
    ],
  }, {
    text: 'En el mundo, se hacen 2.000 millones de minutos por día de llamadas y videollamada.',
    highlight: '2.000 millones',
    sources: [
      {text: 'Expanded Ramblings, mayo 2018', url: 'https://expandedramblings.com/index.php/whatsapp-statistics/'},
    ]
  }, {
    text: 'Argentina representa el 2,1% de ese tráfico.',
    highlight: '2,1%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/whatsapp.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentixs realizamos 15.330.000.000 minutos de llamadas y videollamadas de Whatsapp al año.',
      highlight: ' 15.330.000.000',
    },
    all: {
      perSecond: '486 minutos',
      perDay: '42.000.000 minutos',
      perYear: '15.330.000.000 minutos',
    },
    one: {
      perSecond: null,
      perDay: '1 minuto',
      perWeek: null,
      perMonth: '31 minutos',
      perYear: '369 minutos',
      note: '*Si todos los argentinos conectados usaran Whatsapp.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
