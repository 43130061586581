export default {
  // Info general para iconos
  icon: 'netflix',
  slug: 'netflix-horas',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Vimos **clock** horas de video.',
    clock: {
      valuePerSecond: 34.03,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se ven 140 millones de horas de contenido por día',
    highlight: '140 millones',
    sources: [
      {text: 'Bussiness Insider, junio 2019', url: 'https://www.businessinsider.com/how-to-search-on-netflix?utm_source=feedly&utm_medium=webfeeds'},
    ],
  }, {
    text: 'Argentina representa el 2,1% de los accesos a Netflix.',
    highlight: '2,1%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/netflix.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs vemos 1.073.100.000 horas de contenido al año en Netflix.',
      highlight: '20.000.000',
    },
    all: {
      perSecond: '34 horas',
      perDay: '2.940.000 horas',
      perYear: '1.073.100.000 horas',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: null,
      perMonth: '2 horas',
      perYear: ' 26 horas',
      note: '*Si todos los argentinos conectados usaran Netflix.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
