

export const stripes = [
  {text: 'Somos 45.376.763\n de argentinos', highlight: '45.376.763', url: '/cuantos-somos'},
  {text: 'Somos 41.586.960\n de usuarios de Internet', highlight: '41.586.960', url: '/cuantos-somos'},
  {text: '37,52mbps es la velocidad media\n de descarga de Internet', highlight: '37,52mbps', url: '/cuantos-somos'},
  {text: '124 líneas móviles habilitadas\n cada 100 habitantes', highlight: '124', url: '/mobile'},
  {text: '4hs. 32min es el tiempo promedio\n de uso del teléfono móvil', highlight: '4hs. 32min', url: '/mobile'},
  {text: '70% de las líneas móviles\n utiliza sistema prepago', highlight: '70%', url: '/mobile'},
];

export default stripes;