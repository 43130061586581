export default {
  // Info general para iconos
  icon: 'email',
  slug: 'email-enviados',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Enviamos **clock** emails.',
    clock: {
      valuePerSecond: 219333.33,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se mandan 188 millones de correos electrónicos por minuto.',
    highlight: '188 millones',
    sources: [
      {text: 'Visual Capitalist, marzo 2019 ', url: 'https://www.visualcapitalist.com/what-happens-in-an-internet-minute-in-2019/'},
    ],
  }, {
    text: 'Argentina representa el 7% de ese tráfico.',
    highlight: '7%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/gmail.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs enviamos 6.916.896.000.000 emails al año.',
      highlight: '6.916.896.000.000',
    },
    all: {
      perSecond: '219.333 emails',
      perDay: '18.950.400.000 emails',
      perYear: '6.916.896.000.000 emails',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: '3.190 emails',
      perMonth: '13.859 emails',
      perYear: '166.312 emails',
      note: '*Si todos los argentinos conectados enviaran correos electrónicos.',
    }
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
