export default {
  // Info general para iconos
  icon: 'ecommerce',
  slug: 'gasto-ecommerce',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Gastamos **clock** en comercio electrónico.',
    clock: {
      valuePerSecond: 19168.57,
      prepend: '$',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'Argentina hizo un gasto de $403.278 millones en los 12 meses del 2019.',
    highlight: '$403.278 millones',
    sources: [
      {text: 'CACE, diciembre 2019', url: 'https://cace-static.s3.us-west-2.amazonaws.com/uploads/estudios/estudio-anual-comercio-electronico-2019-resumen.pdf'},
    ],
  }, {
    text: 'Calculamos un incremento de 50% en 2020 entre inflación y crecimiento real: $604.500 millones.',
    highlight: '$604.500 millones',
    sources: [
      {text: 'OIA, junio 2020', url: 'https://inter.net.ar/'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs gastaremos $604.500.000.000 en comercio electrónico en todo el año 2020.',
      highlight: '$604.500.000.000',
    },
    all: {
      perSecond: '$19.169',
      perDay: '$1.656.164.384',
      perYear: '$604.500.000.000',
    },
    one: {
      perSecond: null,
      perDay: '$40',
      perWeek: null,
      perMonth: '$1.211',
      perYear: '$14.534',
      note: '*Si todos los argentinos conectados usaran comercio electrónico.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [{
    text: 'En el 2019, el comercio electrónico creció un 76% con respecto al 2018.\n',
    highlight: '',
  }],
};