export default {
  // Info general para iconos
  icon: 'google',
  slug: 'google-busquedas',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Buscamos **clock** cosas.',
    clock: {
      valuePerSecond: 696.67,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se realizan 3,8 millones de búsquedas por minuto.',
    highlight: '3,8 millones',
    sources: [
      {text: 'Visual Capitalist, marzo 2019', url: 'https://www.visualcapitalist.com/what-happens-in-an-internet-minute-in-2019/'},
    ],
  }, {
    text: 'Argentina representa el 1,1% del total de búsquedas.',
    highlight: '1,1%',
    sources: [
      {text: 'Alexa, mayo 2020', url: 'https://www.alexa.com/siteinfo/google.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs realizamos 21.970.080.000 búsquedas al año en Google.',
      highlight: '21.970.080.000',
    },
    all: {
      perSecond: '697 búsquedas',
      perDay: '60.192.000 búsquedas',
      perYear: '21.970.080.000 mbúsquedas',
    },
    one: {
      perSecond: null,
      perDay: '1 búsquedas',
      perWeek: null,
      perMonth: '44 búsquedas',
      perYear: '528 búsquedas',
      note: '*Si todos los argentinos conectados usaran Google.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
