export default {
  // Info general para iconos
  icon: 'facebook',
  slug: 'facebook-comentarios',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Hicimos **clock** comentarios.',
    clock: {
      valuePerSecond: 33,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, Facebook tiene 2.410 millones de usuarios mensualmente activos.',
    highlight: '2.410 millones',
    sources: [
      {text: 'Hootsuite, noviembre 2019', url: 'https://blog.hootsuite.com/facebook-statistics/'},
    ],
  }, {
    text: 'Argentina representa el 1% de ese total.',
    highlight: '1%',
    sources: [
      {text: 'Alexa, mayo 2020 ', url: 'https://www.alexa.com/siteinfo/facebook.com'},
    ]
  }, {
    text: 'Cada argentinx hace un promedio de 33 comentarios por segundo.',
    highlight: '33 comentarios',
    sources: [
      {text: 'DataReportal, Digital 2020 Argentina - chart 46, enero 2020', url: 'https://datareportal.com/reports/digital-2020-argentina'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs hacemos 1.040.688.000 comentarios al año en Facebook.',
      highlight: '1.040.688.000',
    },
    all: {
      perSecond: '33 comentarios',
      perDay: '2.851.200 comentarios',
      perYear: '1.040.688.000 comentarios',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: null,
      perMonth: '2 comentarios',
      perYear: '25 comentarios',
      note: '*Si todos los argentinos conectados usaran Facebook.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
