

export const howManyWeAre = {
  // Información general
  title: 'Sobre OIA - Observatorio de Internet en Argentina',

  // Subsección proyecto
  project: {
    logo: 'oia',
    title: 'Proyecto',
    texts: [{
      text: 'Séptimo año de OIA',
      highlight: '',
    }, {
      text: 'Nuestro objetivo: reunir, organizar y difundir la información disponible acerca de usos y prácticas de Internet en Argentina para facilitar su comprensión y difusión.',
      highlight: 'Nuestro objetivo',
    }, {
      text: 'Buscamos la información, la clasificamos y la compartimos. Somos un mashup de datos generados por otros.',
      highlight: '',
    }, {
      text: 'Distintas fuentes miden la información con distintas herramientas. \n' +
        'Distintas fuentes difunden información con diferentes objetivos. \n' +
        'Distintas fuentes dan información de distintos momentos.\n' +
        'Por lo tanto: las fuentes pueden dar distinta información sobre un mismo ítem. \n',
      highlight: '',
    }, {
      text: 'Las fuentes no son homogéneas. Lo que mostramos tampoco lo es.',
      highlight: '',
    }, {
      text: 'La frecuencia de actualización está determinada por las diversas fuentes utilizadas.',
      highlight: '',
    }, {
      text: 'La información de esta versión es de Agosto de 2020.',
      highlight: '',
    }],
  },

  // Subsección proyecto
  alliances: {
    logo: 'flacso',
    title: 'Alianzas',
    texts: [{
      text: 'Una de las formas de conocer la sociedad de hoy es conocer la sociedad en Internet. OIA en FLACSO es un proyecto que intenta conocer y poner a disposición de un público amplio, información sobre el uso de Internet en Argentina',
      highlight: 'FLACSO',
    }, {
      text: 'Las preguntas de OIA tienen respuestas que cambian constantemente y que plantean nuevas preguntas. \n' +
        'Las formas de medir actividad, la expansión y el alcance de Internet y su impacto, están empezando a ser una medida de nuestra vida cotidiana en línea y fuera de línea.',
      highlight: '',
    }, {
      text: 'Desde FLACSO también queremos investigar y difundir las formas de medir Internet como una manera de conocer su impacto en nuestra vida en sociedad.',
      highlight: 'FLACSO',
    }, {
      text: 'Alberto Quevedo -  Director',
      highlight: 'Alberto Quevedo',
    }],
  },

  // Subsección proyecto
  team: {
    title: 'Equipo 2020',
    people: [
      {name: 'Daniel Pinkasz', role: 'dirección académica', url: 'https://www.linkedin.com/in/danielpinkasz/'},
      {name: 'Tomas Wainer ', role: 'responsable de gestión de datos', url: 'https://www.linkedin.com/in/tomas-wainer-7a377311a/'},
      {name: 'José María Sollima', role: 'marketimetrics', url: 'https://www.linkedin.com/in/jos%C3%A9-mar%C3%ADa-sollima/'},
      {name: 'Aarón Navia', role: 'contenidos', url: 'https://www.linkedin.com/in/aaronnavia/'},
      {name: 'Cintia Di Cónsoli', role: 'UX', url: 'https://www.linkedin.com/in/cintia-di-c%C3%B3nsoli-81a53888/'},
      {name: 'Matias Cerrotta ', role: 'programación', url: 'https://www.linkedin.com/in/matias-cerrotta-74010b30/'},
      {name: 'Geored', role: 'hosting', url: 'https://www.geored.com/'},
      {name: 'Gisela Castro', role: 'producción', url: 'https://www.linkedin.com/in/gisela-castro-246a8b32/'},
      {name: 'Vivana Moressi', role: 'rrpp', url: 'https://www.linkedin.com/in/viviana-moressi-98279a18b/'},
      {name: 'Sergio Rosemblat', role: 'dirección', url: 'https://www.linkedin.com/in/sergiorosemblat/'},
    ],
  }
};

export default howManyWeAre;