import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router";
import Clock from "../../components/clock";
import data from "../../utils/data/clocks/all";
import {Typography} from "@material-ui/core";
import TimeCount from "../../components/widget-clocks/components/timeCount";
import {withStyles} from "@material-ui/core/styles";

class EmbedClocks extends Component {
  constructor(props) {
    super(props);

    let param = new URLSearchParams(props.location.search).get('f');
    let clocks = [];
    if (param) {
      clocks = param.split(',')
    }
    let dataClocks = data.filter((item) => {
      return clocks.includes(item.slug);
    })

    this.state = {
      dataClocks: dataClocks,
    }
  }

  render() {
    let { dataClocks } = this.state;
    let { classes } = this.props;
    return (
     <Fragment>
       <div className={classes.headerRoot}>
         <Typography color="primary">
           Llevás viendo esta información hace <Typography component="span" className={classes.textHighlight}><TimeCount /></Typography>
         </Typography>
         <Typography className={classes.textHighlight}>Mientras tanto, en Argentina pasó todo esto:</Typography>
       </div>
       {dataClocks.map((data, index) =>
         <Clock
           key={index}
           icon={data.icon}
           slug={data.slug}
           main={data.main}
           topItems={data.topItems}
           inArgentina={data.inArgentina}
           bottomItems={data.bottomItems}
         />
       )}
     </Fragment>
    );
  }
}

const styles = theme => ({
  headerRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  textHighlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withRouter(withStyles(styles)(EmbedClocks));