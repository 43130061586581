export default {
  // Info general para iconos
  icon: 'youtube',
  slug: 'youtube-horas',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Vimos **clock** horas de video.',
    clock: {
      valuePerSecond: 150.46,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se ven 1.000 millones de horas de video por día.',
    highlight: '1.000 millones',
    sources: [
      {text: 'YouTube, marzo 2020', url: 'https://www.youtube.com/intl/en-GB/about/press/'},
    ],
  }, {
    text: 'Argentina representa el 1,3% de las visitas.',
    highlight: '1,3%',
    sources: [
      {text: 'Alexa, mayo 2020 ', url: 'https://www.alexa.com/siteinfo/youtube.com#?sites=youtube.com&sites=google.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs vemos 4.745.000.000 horas de video al año en YouTube.',
      highlight: '4.745.000.000',
    },
    all: {
      perSecond: '150 horas',
      perDay: '13.000.000 horas',
      perYear: '4.745.000.000 horas',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: '2 horas',
      perMonth: '9 horas',
      perYear: '114 horas',
      note: '*Si todos los argentinos conectados usaran YouTube.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};
