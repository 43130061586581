export default {
  // Info general para iconos
  icon: 'twitter',
  slug: 'twitter-tweets',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Escribimos **clock** tweets.',
    clock: {
      valuePerSecond: 99.7,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En el mundo, se envían 9.064 tweets por segundo.',
    highlight: '9.064',
    sources: [
      {text: 'Internet Live Stats, julio 2020', url: 'https://www.internetlivestats.com/twitter-statistics/'},
    ],
  }, {
    text: 'Argentina representa el 1,1% de ese tráfico.',
    highlight: '1,1%',
    sources: [
      {text: 'Alexa, mayo 2020 ', url: 'https://www.alexa.com/siteinfo/twitter.com'},
    ]
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs enviamos 3.144.265.344 tweets al año.',
      highlight: '3.144.265.344',
    },
    all: {
      perSecond: '100 tweets',
      perDay: '8.614.426 tweets',
      perYear: '3.144.265.344 tweets',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: '1 tweet',
      perMonth: '6 tweets',
      perYear: '76 tweets',
      note: '*Si todos los argentinos conectados usaran Twitter.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [],
};