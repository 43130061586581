import React, {Component} from 'react';
import dataHowManyWeAre from "../../utils/data/how-many-we-are";
import PageHowManyWeAre from "./page-how-many-we-are";

class PageHowManyWeAreContainer extends Component {
  render() {
    return (
      <PageHowManyWeAre data={dataHowManyWeAre} />
    );
  }
}

export default PageHowManyWeAreContainer;