

export const howManyWeAre = {
  // Información general
  title: 'Cuántos somos',

  // Subsección cabecera
  subheader: {
    title: {
      text: 'Somos 45.376.763',
      highlight: '45.376.763',
      sources: [{text: 'INDEC, estimado 1/7/2020', url: 'https://www.indec.gob.ar/'}],
    },

    highlights: [{
      text: 'Somos 41.586.960\nde usuarios de Internet',
      highlight: '41.586.960',
      sources: [{text: 'InternetWorldStats, julio 2019', url: 'https://www.internetworldstats.com/stats10.htm#spanish'}],
    }, {
      text: 'El 92,2% de la población\ntiene acceso a Internet',
      highlight: '92,2%',
      sources: [{text: 'InternetWorldStats, julio 2019', url: 'https://www.internetworldstats.com/stats10.htm#spanish'}],
    }],

    items: {
      values: [
        {text: 'Velocidad media de descarga\n37,52mbps', highlight: '37,52mbps'},
        {text: 'Hogares con Internet fija\n62,86%', highlight: '62,86%'},
      ],
      sources: [{text: 'ENACOM, marzo 2020', url: 'https://indicadores.enacom.gob.ar/'}],
    },
    every100: {
      values: [
        {text: 'Accesos a Internet fija\n19,48', highlight: '19,48'},
        {text: 'Suscripciones de TV paga\n21,41', highlight: '21,41'},
        {text: 'Líneas móviles habilitadas\n123,80', highlight: '123,80'},
      ],
      sources: [{text: 'ENACOM, marzo 2020', url: 'https://indicadores.enacom.gob.ar/'}],
    },
  },

  // Subsección de dispositivos por grupos de edad
  byAge: {
    text: 'Uso de Internet y dispositivos por grupos de edad',
    highlight: '',
    sources: [
      {text: 'INDEC: Acceso y uso de tecnologías de la información y la comunicación. \n' +
          'Encuesta Permanente de Hogares. Cuarto trimestre de 2019, diciembre 2019', url: 'https://www.indec.gob.ar/uploads/informesdeprensa/mautic_05_20A36AF16B31.pdf'},
    ],
    data: [{
      title: 'por grupo de edad',
      type: 'celular',
      total: 84.3,
      icon: 'phone',
      data: [
        {text: '4 A 12 AÑOS', value: 50.1},
        {text: '30 A 64 AÑOS', value: 94.6},
        {text: '13 A 17 AÑOS', value: 88.1},
        {text: '65 AÑOS Y MÁS', value: 67.7},
        {text: '18 A 29 AÑOS', value: 95.9},
      ],
    }, {
      title: 'por grupo de edad',
      type: 'computadora',
      total: 84.3,
      icon: 'computer',
      data: [
        {text: '4 A 12 AÑOS', value: 36.9},
        {text: '30 A 64 AÑOS', value: 43.1},
        {text: '13 A 17 AÑOS', value: 56.0},
        {text: '65 AÑOS Y MÁS', value: 19.0},
        {text: '18 A 29 AÑOS', value: 50.7},
      ],
    }, {
      title: 'por grupo de edad',
      type: 'Internet',
      total: 84.3,
      icon: 'wifi',
      data: [
        {text: '4 A 12 AÑOS', value: 72.1},
        {text: '30 A 64 AÑOS', value: 85.3},
        {text: '13 A 17 AÑOS', value: 90.7},
        {text: '65 AÑOS Y MÁS', value: 47.7},
        {text: '18 A 29 AÑOS', value: 91.9},
      ],
    }],
  },

  // Subsección de dispositivos por región
  byRegion: {
    text: 'Uso de Internet y dispositivos por región',
    highlight: '',
    sources: [
      {text: 'INDEC: Acceso y uso de tecnologías de la información y la comunicación. \n' +
        'Encuesta Permanente de Hogares. Cuarto trimestre de 2019, diciembre 2019', url: 'https://www.indec.gob.ar/uploads/informesdeprensa/mautic_05_20A36AF16B31.pdf'},
    ],
    data: [{
      text: 'NOROESTE',
      values: [
        {icon: 'phone', value: 78.7},
        {icon: 'computer', value: 36.3},
        {icon: 'wifi', value: 80.4},
      ],
    }, {
      text: 'CUYO',
      values: [
        {icon: 'phone', value: 81.8},
        {icon: 'computer', value: 42.8},
        {icon: 'wifi', value: 77.3},
      ],
    }, {
      text: 'PATAGONIA',
      values: [
        {icon: 'phone', value: 88.8},
        {icon: 'computer', value: 45.1},
        {icon: 'wifi', value: 86.8},
      ],
    }, {
      text: 'NORESTE',
      values: [
        {icon: 'phone', value: 85.1},
        {icon: 'computer', value: 42.9},
        {icon: 'wifi', value: 76.2},
      ],
    }, {
      text: 'PAMPEANA',
      values: [
        {icon: 'phone', value: 84.5},
        {icon: 'computer', value: 43.3},
        {icon: 'wifi', value: 77.4},
      ],
    }, {
      text: 'CABA',
      values: [
        {icon: 'phone', value: 85.0},
        {icon: 'computer', value: 41.0},
        {icon: 'wifi', value: 81.1},
      ],
    }],
  },

  // Subsección de porcentaje de población con acceso a Internet
  populationWithInternet: {
    text: 'El porcentaje de población con acceso a Internet en Argentina es del 92,2%. Está entre los más altos del mundo.',
    highlight: '92,2%',
    sources: [
      {text: 'InternetWorldStats, junio 2019', url: 'https://www.internetworldstats.com/stats10.htm'},
    ],
    data: [
      {text: 'Argentina', value: 92.2},
      {text: 'Latinonamérica', value: 69.6},
      {text: 'Mundo', value: 58.8},
      {text: 'Europa', value: 87.2},
      {text: 'América del Norte', value: 94.6},
      {text: 'Japón', value: 93.8},
    ],
  },

  // Subsección de velocidad media de descarga
  downloadSpeed: {
    text: 'Velocidad media de descarga',
    highlight: '',
    sources: [
      {text: 'ENACOM, marzo 2020', url: 'https://indicadores.enacom.gob.ar/'},
    ],
    data: {
      texts: [
        '2014 T1', '2014 T2', '2014 T3', '2014 T4',
        '2015 T1', '2015 T2', '2015 T3', '2015 T4',
        '2016 T1', '2016 T2', '2016 T3', '2016 T4',
        '2017 T1', '2017 T2', '2017 T3', '2017 T4',
        '2018 T1', '2018 T2', '2018 T3', '2018 T4',
        '2019 T1', '2019 T2', '2019 T3', '2019 T4',
        '2020 T1',
      ],
      values: [
        3.62, 3.76, 3.87, 4.16, // 2014
        4.35, 4.55, 4.79, 4.99, // 2015
        5.08, 5.42, 5.94, 6.34, // 2016
        6.62, 7.16, 8.34, 12.01, // 2017
        13.22, 13.85, 15.36, 15.95, // 2018
        17.38, 20.35, 24.18, 28.26, // 2019
        37.52, // 2020
      ],
    },
    dataMobile: {
      texts: [
        '2014 T4',
        '2015 T4',
        '2016 T4',
        '2017 T4',
        '2018 T4',
        '2019 T4',
        '2020 T1',
      ],
      values: [
        4.16, // 2014 T4
        4.99, // 2015 T4
        6.34, // 2016 T4
        12.01, // 2017 T4
        15.95, // 2018 T4
        28.26, // 2019 T4
        37.52, // 2020 T1
      ],
    },
  },
};

export default howManyWeAre;