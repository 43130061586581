

export const mobile = {
  // Información general
  title: 'Mobile',

  // Subsección de tiempo promedio de uso
  averageUse: {
    text: 'El tiempo promedio de uso por día es 4hs. 32min.',
    highlight: '4hs. 32min.',
    icon: 'mobile',
    sources: [
      {text: 'Datareporta.com/Digital2020: Argentina-chart 25, enero2020', url: 'https://datareportal.com/reports/digital-2020-argentina'},
    ],
  },

  // Subsección de Líneas móviles habilitadas
  mobileLines: {
    items: [{
      fact: {
        text: 'Hay 58.000.000 de líneas habilitadas*',
        highlight: '58.000.000',
        sources: [
          {text: 'Datareporta.com/Digital2020: Argentina-chart 55, enero2020 ', url: 'https://datareportal.com/reports/digital-2020-argentina'},
        ],
      },
      conclusion: {
        text: '2.000.000\nmenos que hace 1 año',
        highlight: '2.000.000',
        sources: [
          {text: 'Datareporta.com/Digital2019: Argentina-chart 47, enero2019 ', url: 'https://datareportal.com/reports/digital-2019-argentina'},
        ],
      }
    }, {
      fact: {
        text: 'Hay 124 líneas habilitadas cada 100 habitantes*',
        highlight: '124',
        sources: [
          {text: 'ENACOM, diciembre 2019', url: 'https://datosabiertos.enacom.gob.ar/visualizations/29940/penetracion-nacional-de-la-telefonia-movil-accesos-por-cada-100-habitantes/'},
        ],
      },
      conclusion: {
        text: '6\nmenos que hace 1 año',
        highlight: '6',
        sources: [
          {text: 'ENACOM, diciembre 2019', url: 'https://datosabiertos.enacom.gob.ar/visualizations/29940/penetracion-nacional-de-la-telefonia-movil-accesos-por-cada-100-habitantes/'},
        ],
      }
    }],
    data: {
      texts: [
      'ABR-JUN 2013', 'OCT-DIC 2013',
      'ABR-JUN 2014', 'OCT-DIC 2014',
      'ABR-JUN 2015', 'OCT-DIC 2015',
      'ABR-JUN 2016', 'OCT-DIC 2016',
      'ABR-JUN 2017', 'OCT-DIC 2017',
      'ABR-JUN 2018', 'OCT-DIC 2018',
      'ABR-JUN 2019', 'OCT-DIC 2019',
      'ENE-MAR 2020'
      ],
      values: [
        156, 159, // 2013
        143, 143, // 2014
        139, 143, // 2015
        143, 146, // 2016
        143, 140, // 2017
        134, 131, // 2018
        129, 125, // 2019
        124, // 2020
      ],
    },
    dataMobile: {
      texts: [
        'OCT-DIC 2013',
        'OCT-DIC 2014',
        'OCT-DIC 2015',
        'OCT-DIC 2016',
        'OCT-DIC 2017',
        'OCT-DIC 2018',
        'OCT-DIC 2019',
        'ENE-MAR 2019',
      ],
      values: [
        159, // 2013
        143, // 2014
        143, // 2015
        146, // 2016
        140, // 2017
        131, // 2018
        125, // 2019
        124, // 2020
      ],
    },
  },

  // Subsección de Características de uso
  mobileUse: [{
    text: '8 de cada 10 personas tiene acceso al celular en la Argentina',
    highlight: '8 de cada 10',
    sources: [
      {text: 'TELAM, mayo 2019', url: 'https://www.telam.com.ar/notas/201905/357981-tecnologia-celulares.html'}
    ],
    graph: 'icon',
    data: [8, 2],
  }, {
    text: 'El 19% navega con 2G',
    highlight: '19%',
    sources: [
      {text: 'Datareporta.com/Digital2020: Argentina-chart 55, enero2020 ', url: 'https://datareportal.com/reports/digital-2020-argentina'}
    ],
    graph: 'pie',
    data: [19, 81],
  }, {
    text: 'El 70% de las líneas utilizan sistemas de prepago',
    highlight: '70%',
    sources: [
      {text: 'Datareporta.com/Digital2020: Argentina-chart 55, enero2020 ', url: 'https://datareportal.com/reports/digital-2020-argentina'}
    ],
    graph: 'pie',
    data: [70, 30],
  }],

  // Subsección de Características de uso
  mobileVelocityAndAccessibility: [{
    text: 'Velocidad promedio de descarga',
    highlight: '',
    sources: [
      {text: 'Opensignal, The state of mobile experience May 2019 página 5, mayo 2019', url: 'https://www.opensignal.com/sites/opensignal-com/files/data/reports/global/data-2019-05/the_state_of_mobile_experience_may_2019_0.pdf'}
    ],
    categories: ['COREA DEL SUR', 'NORUEGA', 'CANADÁ', 'ARGENTINA', 'NEPAL', 'ARGELIA', 'IRAQ'],
    data: [52.4, 48.2, 42.5, 12.8, 4.4, 3.1, 1.6],
  }, {
    text: 'Accesibilidad de 4G',
    highlight: '',
    sources: [
      {text: 'Opensignal, The state of mobile experience May 2019 página 11, mayo 2019', url: 'https://www.opensignal.com/sites/opensignal-com/files/data/reports/global/data-2019-05/the_state_of_mobile_experience_may_2019_0.pdf'}
    ],
    categories: ['COREA DEL SUR', 'JAPÓN', 'NORUEGA', 'ARGENTINA', 'ECUADOR', 'ARGELIA', 'UZBEKISTÁN'],
    data: [97.5, 96.3, 95.5, 79.0, 57.1, 56.5, 49.9],
  }],

  // Subsección de Sistemas Operativos
  os: {
    text: 'Sistemas opertivos en Argentina',
    highlight: '',
    sources: [
      {text: 'gs.statcounter, junio 2020', url: 'https://gs.statcounter.com/os-market-share/mobile/argentina'}
    ],
  },

  // Subsección de Apps
  apps: {
    byCategory: {
      text: 'Uso de Apps por categoría',
      highlight: '',
      sources: [
        {text: 'Datareporta.com/Digital2020: Argentina-chart 57, enero2020', url: 'https://datareportal.com/reports/digital-2020-argentina'}
      ],
      data: [
        {text: 'CHAT (MESSENGERS)\n98%', highlight: '98%', icon: 'chat'},
        {text: 'MÚSICA\n67%', highlight: '67%', icon: 'music'},
        {text: 'REDES SOCIALES\n98%', highlight: '98%', icon: 'social-networks'},
        {text: 'MAPAS\n83%', highlight: '83%', icon: 'map'},
        {text: 'ENTRETENIMIENTO O VIDEO\n91%', highlight: '91%', icon: 'entertainment'},
        {text: 'BANCOS\n36%', highlight: '36%', icon: 'bank'},
        {text: 'JUEGOS\n62%', highlight: '62%', icon: 'game'},
        {text: 'CITAS\n11%', highlight: '11%', icon: 'date'},
        {text: 'COMPRAS\n75%', highlight: '75%', icon: 'shop'},
        {text: 'SALUD & FITNESS\n25%', highlight: '25%', icon: 'health'},
      ],
    },
    byUse: {
      text: 'Uso de Apps por categoría',
      highlight: '',
      sources: [
        {text: 'datareporta.com/Digital2020: Argentina-chart 58, enero2020', url: 'https://datareportal.com/reports/digital-2020-argentina'}
      ],
      data: {
        apps: [
          'WHATSAPP MESSENGER',
          'FACEBOOK',
          'INSTAGRAM',
          'FACEBOOK MESSENGER',
          'MERCADOLIBRE',
          'SPOTIFY',
          'NETFLIX',
          'MERCADOPAGO',
          'TWITTER',
          'MICROSOFT WORD',
        ],
        games: [
          'FREE FIRE',
          'CANDY CRUSH SAGA',
          'CLASH ROYALE',
          'TRUCO BLYTS',
          'BRAWL STARS',
          'POKÉMON GO',
          'TRIVIA CRACK',
          'CLASH OF CLANS',
          'CODYCROSS',
          'HEUXJUMP',
        ],
      },
    },
  },
};

export default mobile;