export default {
  // Info general para iconos
  icon: 'sms',
  slug: 'sms-enviados',

  // Texto para el reloj sin descolapsar
  main: {
    text: 'Mandamos **clock** sms.',
    clock: {
      valuePerSecond: 273.75,
      prepend: '',
      append: '',
    },
  },

  // Items que se muestran ARRIBA del grafico "En Argentina"
  topItems: [{
    text: 'En Argentina, se enviaron 2.175.994.230 sms en el último trimestre de 2019.',
    highlight: '2.175.994.230',
    sources: [
      {text: 'Enacom, marzo 2020', url: 'https://datosabiertos.enacom.gob.ar/dataviews/240989/numero-de-sms-enviados/'},
    ],
  }],

  // Datos para gráfico "En Argentina"
  inArgentina: {
    main: {
      text: 'Lxs argentinxs enviamos 8.633.020.584 sms al año.',
      highlight: '8.633.020.584',
    },
    all: {
      perSecond: '274 sms',
      perDay: '23.652.111 sms',
      perYear: '8.633.020.584 sms',
    },
    one: {
      perSecond: null,
      perDay: null,
      perWeek: null,
      perMonth: '17 sms',
      perYear: '207 sms',
      note: '*Si todos los argentinos conectados enviaran mensajes de texto.',
    },
  },

  // Items que se muestran DEBAJO del gráfico "En Argentina"
  bottomItems: [{
    text: 'Suponemos que la cantidad de sms durante 2020 será semejante a la del 4º trimestre de 2019. Somos optimistas.',
    highlight: '',
  }],
};
