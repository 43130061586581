import React, {Component} from 'react';
import dataMobile from "../../utils/data/mobile";
import PageMobile from "./page-mobile";

class PageMobileContainer extends Component {
  render() {
    return (
      <PageMobile data={dataMobile} />
    );
  }
}

export default PageMobileContainer;