import React, {Component} from 'react';
import WidgetClocks from "./widget-clocks";
import data from "../../utils/data/clocks/all";

class WidgetClocksContainer extends Component {
  render() {
    return (
      <WidgetClocks dataClocks={data} />
    );
  }
}

export default WidgetClocksContainer;